<template>
  <section
    v-if="languages.length > 1"
    class="language-selector"
    @click="show_options = !show_options"
    @mouseleave="show_options = false"
    v-click-outside="() => show_options = false"
  >
    <img
      class="language-selector__selected"
      :data-cy="`${selected_language}`"
      :src="`/static/icons/countries/${selected_language || languages[0]}.svg`"
      :alt="`${selected_language} flag`"
    >

    <transition name="show-language-options">
      <div v-if="show_options" class="language-selector__options">
        <img
          v-for="language in languages"
          :key="`language-selector-flag-${language}`"
          :src="`/static/icons/countries/${language}.svg`"
          :alt="`${language} language flag`"
          :data-cy="`${language}`"
          @click="update_selected_language(language)"
        >
      </div>
    </transition>
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { CLIENT_STORE } from "../../constants/other"
import { CONFIGURATION_STORE } from '../../../Admin/constants/others_constants'
import { UPDATE_SELECTED_LANGUAGE } from '../../stores/Client/constants'

export default {
  props: {
    admin_imported: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      show_options: false,
    }
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "selected_language",
      "translations_object"
    ]),
    ...mapState(CONFIGURATION_STORE, [
      "translations"
    ]),
    languages() {
      return Object.keys(this.admin_imported ? this.translations : this.translations_object)
    }
  },
  methods: {
    ...mapMutations(CLIENT_STORE, {
      update_selected_language: UPDATE_SELECTED_LANGUAGE
    }),
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;

  .language-selector {
    position: relative;
    display: inline-flex;
    height: $menu-height;
    padding: 10px 0;
    margin-right: 15px;
    vertical-align: middle;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    z-index: 3;

    @media (max-width: $tablet) {
      height: 45px;
      padding: 8px 0;
      margin-left: 10px;
      margin-right: 0;
    }

    img {
      display: block;
      width: 30px;
      height: 25px;
      object-fit: cover;
      border-radius: 6px;
    }

    &__options {
      position: absolute;
      width: 60px;
      padding:  0 10px 10px 10px;
      box-sizing: border-box;
      background: var(--background_heavy);
      top: calc(#{$menu-height} - 10px);
      left: -15px;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      border-top-color: transparent;
      box-shadow: 0px 7px 15px -5px $shadow-color;

      img {
        display: block;
        object-fit: cover;
        margin: 0 auto 10px;
        border-radius: 6px;
        width: 30px;
        height: 25px;

        &:first-child {
          margin-top: 10px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .show-language-options-enter-active, .show-language-options-leave-active {
    transition: .5s;
  }
  .show-language-options-enter, .show-language-options-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(-20px);
  }
</style>
